import React, { useState } from 'react';
import styles from './resume.module.css';
import gStyles from '../../../global.module.css';
import Colors from '../../../const/colors';
import { ResumeData } from '../../../const/resume';
import ColoredLine from '../../../components/coloredLine';
import ResumeItems from './resumeItems';
import MyButton from '../../../components/buttons/button';
import ReactGA from 'react-ga4';

export default function ResumeContent() {
  const {
    maskBackground,
    resumeFilterButtons,
    smallerButton,
    activeStyleFilter,
    magentaButton,
    noPrint,
    qr,
    printOnly,
  } = styles;
  const [activeFilter, setActiveFilter] = useState(0);
  const [resumeData, setResumeData] = useState(ResumeData);

  const noPrintIDs = [1, 7];
  const gaClick = (button) => {
    console.log('awesome resume filter ga click happening');
    ReactGA.event({
      category: 'About Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };
  const filterResumeItems = (filterType) => {
    gaClick(`Resume - ${filterType} filter`);
    const originalResumeData = JSON.parse(JSON.stringify(ResumeData));
    let filteredResumeItems = [];
    originalResumeData.experience.forEach((item) => {
      if (filterType === 'education') {
        setActiveFilter(2);
        if (item.edBool === true) {
          item.noPrint = false; // print all education items
          filteredResumeItems.push(item);
        }
      } else if (filterType === 'development') {
        setActiveFilter(1);
        if (item.devBool === true) {
          item.noPrint = false; // print all development items
          filteredResumeItems.push(item);
        }
      } else {
        setActiveFilter(0);
        if (noPrintIDs.includes(item.id)) {
          item.noPrint = true; // remove lame items when printing all
        }
        filteredResumeItems.push(item);
      }
    });
    originalResumeData.experience = filteredResumeItems;
    setResumeData(originalResumeData);
  };
  return (
    <div>
      <div>
        <h1 className={maskBackground} style={{ display: 'inline' }}>
          Experience
          <img
            src='/images/portfolioQR.png'
            alt=''
            className={`${qr} ${printOnly}`}
          />
        </h1>
        <div className={resumeFilterButtons}>
          <MyButton
            text={'All'}
            buttonStyle={`${gStyles.resumeButton} ${smallerButton} ${
              activeFilter === 0 ? activeStyleFilter : magentaButton
            }`}
            functionHandler={filterResumeItems}
            functionParam='all'
          />
          <MyButton
            text={'Dev'}
            functionHandler={filterResumeItems}
            functionParam='development'
            buttonStyle={`${gStyles.resumeButton} ${smallerButton} ${
              activeFilter === 1 ? activeStyleFilter : magentaButton
            }`}
          />
          <MyButton
            text={'Edu'}
            functionHandler={filterResumeItems}
            functionParam='education'
            buttonStyle={`${gStyles.resumeButton} ${smallerButton} ${
              activeFilter === 2 ? activeStyleFilter : magentaButton
            }`}
          />
        </div>
      </div>
      <ColoredLine color={Colors[0]} />
      {resumeData.experience.map((t) => (
        <ResumeItems t={t} key={Math.random()} color={Colors[0]} />
      ))}
      <div className={noPrint}>
        <h1 className={maskBackground}>Education</h1>
        <ColoredLine color={Colors[1]} />
        {resumeData.education.map((t) => (
          <ResumeItems t={t} key={Math.random()} color={Colors[2]} ed={true} />
        ))}
      </div>
    </div>
  );
}
