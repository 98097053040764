const Photos = [
  {
    src: 'https://nathanbirch.one/images/personal/Birch1.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch2.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch3.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch4.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch5.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch6.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch7.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch8.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch9.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch10.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch11.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch12.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch13.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch14.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch15.jpg',
    width: 4,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch16.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch17.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch18.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch19.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch20.jpg',
    width: 4,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch21.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch23.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch24.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch25.jpg',
    width: 3,
    height: 2,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch26.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch27.jpg',
    width: 2,
    height: 3,
  },
  {
    src: 'https://nathanbirch.one/images/personal/Birch28.jpg',
    width: 2,
    height: 3,
  },
];

export default Photos;
