import React from 'react';
import ReactDOM from 'react-dom';
import App from '../src/nav';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

(async (_) => {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactDOM.render(<App />, document.getElementById('root'));
})();

/* deploy: 
    npm run build
    test it with the following build command: CI=true npm run build
    npx netlify deploy --prod
*/
serviceWorker.unregister();
