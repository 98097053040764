import React, { Component } from 'react';
import styles from './loading.module.css';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PuffLoader from 'react-spinners/PuffLoader';

class Loading extends Component {
  state = {
    redirect: false,
  };

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.id);
  }

  render() {
    return this.state.redirect ? (
      <Redirect to='/home' />
    ) : (
      <div>
        <Helmet>
          <meta charSet='utf-8' />
          <link rel='canonical' href='https://nathanbirch.one/' />
          <meta
            name='description'
            content='Nathan Birch - Product Manager | Project Manager | Software Engineer'
          />
        </Helmet>
        <div className={styles.loadingHeader}>
          <PuffLoader
            size={'40vw'}
            color={'#fff'}
            style={{ zIndex: 2 }}
            loading={true}
          />
        </div>
      </div>
    );
  }
}

export default Loading;
