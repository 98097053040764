import React, { useState } from 'react';
import styles from './personal.module.css';
import gStyles from '../../global.module.css';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { Button } from 'react-bootstrap';
import Colors from '../../const/colors';
import ColoredLine from '../../components/coloredLine';
import ReactGA from 'react-ga4';

const PLC = () => {
  const [shortVersionBool, setShortVersionBool] = useState(true);
  const gaClick = (button) => {
    ReactGA.event({
      category: 'Personal Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };
  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };
  const likes = [
    'My family',
    'Rexburg, ID',
    'Board Games',
    'Software Engineering',
    'Web Development',
    'Playing Piano',
    'Singing',
    'Playing with my kiddos',
    'Watching awesome movies',
    'Cold cereal',
    'Brazil',
    'Disc golf',
    'Basketball',
    'Church',
    'Swimming',
    'English Mastiffs',
    'Dates with my wife',
    'JavaScript',
    'Learning new things',
    'Late road trips when my kids are unconscious',
    'Teaching',
    'Helping people',
    'Carpentry',
    'Riding my scooter',
  ];
  shuffle(likes);
  const {
    clcMain,
    clcHeader,
    centeredGroup,
    activeButton,
    nonActiveButton,
    noBreakWord,
    fRight,
  } = styles;

  const handleClick = (displayBool) => {
    if (displayBool) {
      gaClick('10-peso version');
    } else {
      gaClick('Long-winded version');
    }
    setShortVersionBool(displayBool);
  };

  return (
    <Slide left>
      <Roll>
        <div className={clcMain}>
          <Fade top cascade>
            <h1 className={clcHeader}>Who am I?</h1>
          </Fade>
          <ColoredLine color={Colors[0]} />
          <div className={centeredGroup}>
            <Button
              className={`${gStyles.resumeButton} ${
                shortVersionBool === true ? activeButton : nonActiveButton
              }`}
              variant='outline-light'
              size='lg'
              type='button'
              onClick={() => {
                handleClick(true);
              }}>
              10-peso version
            </Button>
            <Button
              className={`${gStyles.resumeButton} ${
                shortVersionBool === false ? activeButton : nonActiveButton
              }`}
              variant='outline-light'
              size='lg'
              type='button'
              onClick={() => {
                handleClick(false);
              }}>
              Long-winded version
            </Button>
          </div>
          {shortVersionBool ? (
            <>
              <p>
                The following is a non-comprehensive list of things that I love
                in no particular order... (well, technically it's in the{' '}
                <a
                  href='https://www.google.com/search?q=fisher-yates+shuffle'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Fisher-Yates order
                </a>{' '}
                🤓 ).
              </p>
              <Flip right cascade>
                <ul className={fRight}>
                  {likes.map((like, i) => (
                    <li key={i}>{like}</li>
                  ))}
                </ul>
              </Flip>
            </>
          ) : (
            <Fade top left cascade>
              <div className={noBreakWord}>
                <p>
                  My name is Nathan Birch. I am originally from Spokane,
                  Washington and enjoyed growing up there. I am a member of The
                  Church of Jesus Christ of Latter-day Saints and that has
                  always played a large role in who I am and in what I've chosen
                  to do in my life. When I was 19, I was able to serve a mission
                  for my church in São Paulo, Brasil. I lived there for two
                  years, giving service and teaching people. Those two years
                  were absolutely incredible.{' '}
                </p>
                <p>
                  When I got home, I met sarah; the woman who would soon become
                  my wife. She has been the most amazing part of my life. Having
                  our kids has been such a wonderful, busy, crazy, intense,
                  enjoyable, life-altering experience. They are growing like
                  crazy and each day is seriously an adventure.{' '}
                </p>
                <p>
                  During our first year of marriage, I was able to go to Brigham
                  Young University - Idaho and pursue a Bachelor's Degree in
                  Computer Information Technology. After spending a year in
                  Rexburg going to school, I got an internship with the church
                  working as a full stack web developer, using .Net and
                  AngularJS. I loved this job and was so excited when they hired
                  me on full-time after my internship. I worked while finishing
                  my degree remotely.
                </p>
                <p>
                  After I graduated, we decided to make a change. We accepted a
                  job opportunity with General Motors in Austin, Texas. We
                  thought we'd try out life without winters for a little while.
                  :) It was a beautiful time. The majority of my work for GM was
                  in full stack web development and mobile application
                  development. One of my favorite parts about working there was
                  leading a team of software engineers in Brazil. I loved our
                  daily meetings in Portuguese and working with them.{' '}
                </p>
                <p>
                  During this time, I pursued a Master's Degree in Information
                  Systems from Boston University (online). This was a busy
                  time...work, grad school, and babies. The lessons that we all
                  learned during this time were invaluable. As grateful as I am
                  for those lessons learned and for that busy time, I'm also
                  grateful to be done with school. :) At least with my formal
                  education.{' '}
                </p>
                <p>
                  During my last year with GM, I started teaching an online
                  JavaScript programming class with BYU-I. During my first week
                  teaching, I couldn't believe how much I loved it. We applied
                  to teach on campus and have been so blessed to be given this
                  opportunity. I've now been teaching full-time in the Computer
                  Science and Electrical Engineering Department of Brigham Young
                  University - Idaho since 2019.{' '}
                </p>
                <p>
                  We love being in Rexburg. We love being at this amazing
                  school. Life has always been adventure. Amid all the large
                  changes and life events, I enjoy disc golf, and basketball
                  (and pretty much any other sport out there), carpentry, music,
                  board games, and telling lame jokes to those around me. I love
                  web development. I love solving problems. I love teaching and
                  helping students learn marketable skills.{' '}
                </p>
                <i>
                  ...and yes, the "10-peso version" is referencing{' '}
                  <strong>The Three Amigos</strong>. If you haven't seen it, you
                  should{' '}
                  <a
                    href='https://www.google.com/search?q=watch+the+three+amigos+online'
                    target='_blank'
                    rel='noopener noreferrer'>
                    go watch it
                  </a>
                  . :)
                </i>
              </div>
            </Fade>
          )}
        </div>
      </Roll>
    </Slide>
  );
};

export default PLC;
