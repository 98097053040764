import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './b.module.css';

function MyButton(props) {
  const { linkButton } = styles;
  return (
    <Button
      variant='outline-light'
      size='lg'
      className={`${linkButton} ${props.buttonStyle}`}
      onClick={
        props.functionHandler
          ? () => {
              props.functionHandler(props.functionParam || null);
            }
          : undefined
      }>
      {props.text}
    </Button>
  );
}

export default MyButton;
