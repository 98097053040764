import React, { Component } from 'react';
import gStyles from '../../global.module.css';
import styles from './about.module.css';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopCode,
  faSkiing,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Colors from '../../const/colors';
import ColoredLine from '../../components/coloredLine';
import ReactGA from 'react-ga4';

class CLC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      show: false,
    };
  }

  render() {
    const { clcMain, clcContainer, clcHeader, aboutBtnContainer } = styles;
    const gaClick = (button) => {
      ReactGA.event({
        category: 'About Page',
        action: `${button} Button`,
        label: 'Button clicked!',
      });
    };
    return (
      <Slide left>
        <Bounce>
          <div className={clcMain}>
            <div className={clcContainer}>
              <Fade top cascade>
                <h1 className={clcHeader}>
                  About{' '}
                  <Bounce top cascade>
                    <span>Nathan</span>
                  </Bounce>
                </h1>
              </Fade>
              <ColoredLine color={Colors[1]} />
              <Fade top cascade>
                <div className={aboutBtnContainer}>
                  <div className='row'>
                    <div
                      className={`col d-flex justify-content-center ${gStyles.hov}`}>
                      <Link
                        to={'./project'}
                        className={gStyles.both}
                        onClick={() => {
                          gaClick('Projects');
                        }}>
                        <FontAwesomeIcon
                          icon={faLaptopCode}
                          className={gStyles.both_icon}
                        />
                        <br />
                        <br />
                        <span className={gStyles.both_label}>Projects</span>
                      </Link>
                    </div>
                    <div
                      className={`col d-flex justify-content-center ${gStyles.hov}`}>
                      <Link
                        to={'./personal'}
                        className={gStyles.both}
                        onClick={() => {
                          gaClick('Personal');
                        }}>
                        <FontAwesomeIcon
                          icon={faSkiing}
                          className={gStyles.both_icon}
                        />
                        <br />
                        <br />
                        <span className={gStyles.both_label}>Personal</span>
                      </Link>
                    </div>
                    <div
                      className={`col d-flex justify-content-center ${gStyles.hov}`}>
                      <Link
                        to={'./contact'}
                        className={gStyles.both}
                        onClick={() => {
                          gaClick('Contact');
                        }}>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className={gStyles.both_icon}
                        />
                        <br />
                        <br />
                        <span className={gStyles.both_label}>Contact</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Bounce>
      </Slide>
    );
  }
}

export default CLC;
