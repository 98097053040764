import React, { Component } from 'react';
import gStyles from '../../global.module.css';
import styles from './about.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import Animate from 'react-smooth';
import { Helmet } from 'react-helmet';
import AboutTop from './aboutTop';
import Resume from './resume';
import HamburgerMenu from '../../components/hamburger_menu';
import particles from '../../const/about_particle.js';
import Particles from 'react-particles-js';
import ReactGA from 'react-ga4';

class About extends Component {
  componentDidMount() {
    ReactGA.pageview('About');
  }
  render() {
    const { aboutHeader, aboutFooter } = styles;
    return (
      <Container className={aboutHeader} fluid={true}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>About | Nathan Birch</title>
          <link rel='canonical' href='https://nathanbirch.one/about' />
          <meta
            name='description'
            content='Nathan Birch | About - Product Manager | Project Manager | Software Engineer
                Experienced Project & Product Manager with a demonstrated history of working in computer software industry. 
                Skilled in leading edge project management, web & software development tools & trends. 
                Strong computer engineering base with a BSc in Computer Science focused in Computer Software Engineering from BRAC University. 
                In addition to that minor on Basic Business studies to assist me through product & project management'
          />
        </Helmet>
        <Animate to='1' from='0' attributeName='opacity'>
          <Particles params={particles} className={gStyles.particle} />
          <Row className={gStyles.aboutMain}>
            <HamburgerMenu />
            <Col xl={12} lg={12}>
              <AboutTop />
            </Col>
            <Col xl={12} lg={12}>
              <Resume />
            </Col>
          </Row>
          <Row className={aboutFooter}>
            <Footer />
          </Row>
        </Animate>
      </Container>
    );
  }
}

export default About;
