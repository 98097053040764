import Roll from 'react-reveal/Roll';
import Zoom from 'react-reveal/Zoom';
import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Photos from '../../const/photos';
import ReactGA from 'react-ga4';

function PRI() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const gaClick = (p, i) => {
    ReactGA.event({
      category: 'Personal Page',
      action: `Photo #${i} clicked`,
      label: p.src,
    });
  };

  const openLightbox = useCallback((event, { photo, index }) => {
    gaClick(photo, index);
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Roll right>
      <Zoom right>
        <Gallery photos={Photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={Photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Zoom>
    </Roll>
  );
}

export default PRI;
