import React from 'react';

const ColoredLine = (props) => (
  <hr
    style={{
      color: props.color,
      backgroundColor: props.color,
      height: 1,
      width: props.width || '100%',
    }}
  />
);

export default ColoredLine;
