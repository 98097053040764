import React from 'react';
import styles from './contact.module.css';
import gStyles from '../../global.module.css';
import * as emailjs from 'emailjs-com';
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';
import ClipLoader from 'react-spinners/BarLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ReactGA from 'react-ga4';

class Email_Form extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      loading: false,
      name: '',
      email: '',
      subject: '',
      message: '',
    };
  }

  gaClick(button) {
    ReactGA.event({
      category: 'Contact Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ name: '' });
    this.resetForm();
  }

  handleShow() {
    this.setState({ show: true, loading: false });
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    show: false,
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { email, subject, message, name } = this.state;

    let templateParams = {
      from_name: email,
      to_name: process.env.REACT_APP_EMAIL,
      subject: subject,
      message_html: message,
      name: name,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER
      )
      .then(
        (result) => {
          this.handleShow();
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
  }

  resetForm() {
    this.setState({
      email: '',
      subject: '',
      message: '',
      modal: false,
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    const {
      contactForm,
      contactFormHeader,
      contactInput,
      contactInputText,
      contactSuccessModalBody,
      successCheck,
      contactEmailTextBtn,
    } = styles;
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} className={contactForm}>
        <p className={contactFormHeader}>Contact Form</p>
        <FormGroup className={contactInput}>
          <Form.Control
            type='email'
            value={this.state.email}
            className={contactInputText}
            onChange={this.handleChange.bind(this, 'email')}
            placeholder='Email Address'
            required
          />
        </FormGroup>
        <FormGroup controlId='formBasicName' className={contactInput}>
          <Form.Control
            as='textarea'
            rows='1'
            value={this.state.name}
            onChange={this.handleChange.bind(this, 'name')}
            placeholder='Your Name'
            className={contactInputText}
            required
          />
        </FormGroup>
        <FormGroup controlId='formBasicSubject' className={contactInput}>
          <Form.Control
            as='textarea'
            rows='1'
            value={this.state.subject}
            onChange={this.handleChange.bind(this, 'subject')}
            placeholder='Subject'
            className={contactInputText}
            required
          />
        </FormGroup>
        <FormGroup controlId='formBasicMessage' className={contactInput}>
          <Form.Control
            as='textarea'
            rows='6'
            value={this.state.message}
            placeholder='Your text'
            onChange={this.handleChange.bind(this, 'message')}
            className={contactInputText}
            required
          />
        </FormGroup>

        <Button
          className={gStyles.resumeButton}
          variant='outline-light'
          size='lg'
          onClick={() => {
            this.gaClick('Submit');
          }}
          type='submit'>
          Submit
          <ClipLoader
            size={5} // or 150px
            color={'#ffffff'}
            loading={this.state.loading}
          />
        </Button>

        <Modal
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          show={this.state.show}
          onHide={this.handleClose}
          centered>
          <Modal.Body className={contactSuccessModalBody}>
            <FontAwesomeIcon icon={faCheckCircle} className={successCheck} />
            <h5>
              Thank you,{' '}
              <span>
                <strong>{this.state.name}</strong>!!
              </span>{' '}
              😇
            </h5>
            <h6>Your message was successfully sent.</h6>
            <br />
            <Button
              variant='outline-light'
              size='lg'
              onClick={this.handleClose}
              className={contactEmailTextBtn}>
              Close
            </Button>
          </Modal.Body>
        </Modal>
      </Form>
    );
  }
}

export default Email_Form;
