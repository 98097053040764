import React from 'react';
import styles from './footer.module.css';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  const creationYear = 2021;
  const currentYear = new Date().getFullYear();
  let copyrightText = `Copyright © ${creationYear}`;
  if (currentYear > creationYear) {
    copyrightText += ` - ${currentYear}`;
  }
  const { footer } = styles;

  return (
    <Container className={footer} fluid={true}>
      <Row>
        <Col xl={12}>
          {copyrightText}
          {'  '}
          {/* version number */}
          <strong>Nathan Birch</strong>, <small>V2.1.1</small>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
