import React from 'react';
import styles from './resume.module.css';
// import Colors from '../../const/colors';
// import { ResumeData } from '../../const/resume';
// import ColoredLine from '../coloredLine';
// import ResumeItems from './resumeItems';
// import MyButton from '../button';
import { Row, Col } from 'react-bootstrap';
import ResumeContent from './resumeContent';
import ResumeHeader from './resumeHeader';
import ResumeSide from './resumeSide';

const Resume = React.forwardRef((props, ref) => {
  const { resumeContentPage } = styles;
  return (
    <div ref={ref}>
      <ResumeHeader />
      <div className={resumeContentPage}>
        <Row>
          <Col xl={3} lg={3} md={3} sm={3} xs={12}>
            <ResumeSide />
          </Col>
          <Col xl={9} lg={9} md={9} sm={9} xs={12}>
            <ResumeContent />
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default Resume;
