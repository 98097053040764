import React from 'react';
import styles from './contact.module.css';
import { Row, Col } from 'react-bootstrap';
import SocialIcons from '../../components/social_icons';

function Contact_Left() {
  const { contactText, pHeading1, pHeading2, contactLeftFooter } = styles;
  return (
    <div>
      <Row className={contactText}>
        <Col xl={12}>
          <h1 className={pHeading1}>Get In Touch</h1>
          <p className={pHeading2}>
            Hola <strong>Visitor !!</strong>
            <br />
            Thanks for stopping by. If you have any queries, ideas, projects, or
            questions, feel free to <strong>PING</strong> me. I am always open
            for discussion. You can contact me via the Contact Form{' '}
            <strong>OR</strong> simply click on the social media icon if you are
            more comfortable there.
          </p>
        </Col>
      </Row>

      <Row className={contactLeftFooter}>
        <SocialIcons />
      </Row>
    </div>
  );
}

export default Contact_Left;
