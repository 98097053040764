import React, { Component } from 'react';
import styles from './projects.module.css';
import { Col, Card, Row } from 'react-bootstrap';
import info from '../../const/project_info.json';
import Colors from '../../const/colors';
import ReactGA from 'react-ga4';

export default class PM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      image: '',
    };
  }
  render() {
    const getStyle = () => {
      return {
        color: Colors[Math.floor(Math.random() * Colors.length)],
      };
    };
    const { cont, mainRow, cardCol, cardMain, portImg } = styles;

    const gaClick = (projectTitle) => {
      ReactGA.event({
        category: 'Project Page',
        action: `${projectTitle} project`,
        label: 'Clicked!',
      });
    };
    return (
      <div className={cont}>
        <Row xl={12} className={mainRow}>
          {info.data.map((item) => (
            <Col
              xxl={3}
              xl={4}
              md={6}
              sm={12}
              key={Math.random()}
              className={cardCol}>
              <a
                href={item.link}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  gaClick(item.name);
                }}>
                <Card className={cardMain}>
                  <h2 style={getStyle()}>{item.name}</h2>
                  <footer>
                    {item.tools && 'built using'}
                    <strong title='Source Title' style={{ padding: '0 10px' }}>
                      {item.tools}
                    </strong>
                  </footer>
                  <img
                    src={item.image}
                    className={portImg}
                    style={{ width: '100px' }}
                    alt=''
                  />
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
