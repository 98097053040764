import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { status } from '../const/resume';

export default function Notification() {
  const [notificationOpen, setNotificationOpen] = useState(false);
  let message =
    'Currently, Nathan is not open to new opportunities 😞 ...but feel free to reach out anyways 😊';
  if (status.isLookingBool) {
    message =
      '🎉 Nathan is actively looking for new opportunities. 🎉 Be sure to drop a line. 👍💪';
  }

  const notify = () =>
    toast(message, {
      position: 'top-center',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: setNotificationOpen(false),
    });

  const triggerNotification = () => {
    if (notificationOpen) return;
    notify();
    setNotificationOpen(true);
  };

  setTimeout(() => {
    triggerNotification();
  }, 2000);

  return (
    <ToastContainer
      toastStyle={{
        backgroundColor: 'black',
        color: 'white',
        border: '2px solid #FF3F8E',
      }}
      position='top-center'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
