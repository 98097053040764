import React, { useEffect } from 'react';
import gStyles from '../../global.module.css';
import styles from './home.module.css';
import HomeLeft from './homeLeft';
import HomeRight from './homeRight';
import Footer from '../../components/footer';
import { Container, Row, Col } from 'react-bootstrap';
import Animate from 'react-smooth';
import Particles from 'react-particles-js';
import particles from '../../const/particle.js';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

function Home() {
  useEffect(() => {
    ReactGA.pageview('Home');
  }, []);
  const { AppHeader, AppMain, AppFooter } = styles;
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Home | Nathan Birch</title>
        <link rel='canonical' href='https://nathanbirch.one/home' />
        <meta
          name='description'
          content='Nathan Birch | Home - Product Manager | Project Manager | Software Engineer'
        />
      </Helmet>
      <Animate to='1' from='0' attributeName='opacity'>
        <Particles params={particles} className={gStyles.particle} />
        <Container className={AppHeader} fluid={true}>
          <Row className={AppMain}>
            <Col xl={7}>
              <HomeLeft />
            </Col>
            <Col xl={5}>
              <HomeRight />
            </Col>
          </Row>
          <Row className={AppFooter}>
            <Footer />
          </Row>
        </Container>
      </Animate>
    </div>
  );
}

export default Home;
