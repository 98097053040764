import React from 'react';
import styles from './ham.module.css';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

function Hamburger() {
  const { menuWrap, toggler, hamburger, menu } = styles;
  const gaClick = (button) => {
    ReactGA.event({
      category: 'Hamburger Menu',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };
  return (
    <div className={menuWrap}>
      <input type='checkbox' className={toggler} />
      <div className={hamburger}>
        <div></div>
      </div>
      <div className={menu}>
        <div>
          <div>
            <ul>
              <li>
                <Link
                  to='/home'
                  onClick={() => {
                    gaClick('HOME');
                  }}>
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  to='/about'
                  onClick={() => {
                    gaClick('ABOUT');
                  }}>
                  ABOUT
                </Link>
              </li>
              <li>
                <Link
                  to='/contact'
                  onClick={() => {
                    gaClick('CONTACT');
                  }}>
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hamburger;
