import React from 'react';
import { ResumeData } from '../../../const/resume';
import Colors from '../../../const/colors';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsiveAnimationDuration: 5000,
  indexAxis: 'y',
  type: 'bar',
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 1,
      barPercentage: 0.1,
      borderRadius: 15,
      hoverBackgroundColor: Colors[0],
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        position: 'top',
        color: '#6a6c6e',
        text: 'Years of Experience',
      },

      grid: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: false,
        color: '#6a6c6e',
      },
      gridLines: {
        drawOnChartArea: false,
      },
      ticks: {
        stepSize: 2,
      },
    },
  },
  plugins: {
    legend: {
      position: 'hidden',
    },
  },
};

let dataPoints = [];
let maxYears = 0;
ResumeData.skills.forEach((skill) => {
  const end = skill.dateEnd || new Date();
  const years = parseFloat(
    ((end.getTime() - skill.dateStart.getTime()) / 31536000000).toFixed(1)
  );
  if (years > maxYears) {
    maxYears = years;
  }
  dataPoints.push({
    y: years,
    label: skill.title,
  });
});
dataPoints.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
let labels = [];
let years = [];
dataPoints.forEach((dp) => {
  labels.push(dp.label);
  years.push(dp.y);
});

export const data = {
  labels,
  datasets: [
    {
      yAxisID: 'yAxis',
      label: 'Years',
      data: years,
      borderColor: Colors[1],
      backgroundColor: Colors[2],
    },
  ],
};

export default function SkillChart() {
  return <Bar options={options} data={data} />;
}
