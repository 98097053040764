import React from 'react';
import Bounce from 'react-reveal/Bounce';
import LightSpeed from 'react-reveal/LightSpeed';
import Rotate from 'react-reveal/Rotate';
import Colors from '../../../const/colors';
import ColoredLine from '../../../components/coloredLine';
import SkillChart from './skillChart';
import { ResumeData } from '../../../const/resume';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from './resume.module.css';
import gStyles from '../../../global.module.css';
import ReactGA from 'react-ga4';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './tooltip.css';

export default function ResumeSide() {
  const gaClick = (button) => {
    ReactGA.event({
      category: 'About Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };

  const {
    maskBackground,
    mTop,
    mTopSmall,
    chartContainer,
    printOnly,
    sideTitle,
    sideDesc,
    // qrSide,
    noPrint,
    sideBarMessage,
    softSkillBubble,
    softSkillContainer,
  } = styles;
  return (
    <>
      <Bounce left>
        <h1 className={maskBackground}>Hard Skills</h1>
        <ColoredLine color={Colors[2]} width='100%' />
        <div
          className={chartContainer}
          style={{ height: ResumeData.skills.length * 20 }}>
          <SkillChart />
        </div>
      </Bounce>
      <div className={printOnly}>
        <h1 className={`${maskBackground} ${mTopSmall}`}>Education</h1>
        <ColoredLine color={Colors[0]} width='100%' />
        <p className={sideTitle}>Master's Degree</p>
        <ul className={sideDesc}>
          <li>Computer Information Systems Security</li>
          <li>Boston University (2019)</li>
        </ul>
        <p className={sideTitle}>Bachelor's Degree</p>
        <ul className={sideDesc}>
          <li>Computer Information Technology</li>
          <li>Brigham Young University - Idaho (2016)</li>
        </ul>
        <p className={sideTitle}>Associates's Degree</p>
        <ul className={sideDesc}>
          <li>Spokane Community College (2013)</li>
        </ul>

        {/* <h1 className='maskBackground mTop'>Portfolio</h1>
        <ColoredLine color={Colors[0]} width='100%' />
        <img src='/images/portfolioQR.png' alt='' className={qrSide} /> */}
      </div>
      <LightSpeed>
        <h1 className={`${maskBackground} ${mTop}`}>Soft Skills</h1>
        <ColoredLine color={Colors[2]} width='100%' />
        <div className={softSkillContainer}>
          {ResumeData.softSkills.map((softSkill, i) => (
            <OverlayTrigger
              placement='right'
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id='softSkill-tooltip' {...props} stylename='tooltip'>
                  {softSkill.description}
                </Tooltip>
              )}>
              <div
                key={i}
                className={`${softSkillBubble} ${maskBackground}`}
                style={{ border: `1px solid ${Colors[2]}` }}>
                {softSkill.title}
              </div>
            </OverlayTrigger>
          ))}
        </div>
      </LightSpeed>
      <div className={`${noPrint} ${sideBarMessage}`}>
        <Rotate top left>
          <p>Feel</p>
          <p>free</p>
          <p>to</p>
          <p>drop</p>
          <p>a</p>
          <p>line</p>
          <Link
            to={'./contact'}
            className={`${gStyles.both} ${gStyles.hov}`}
            onClick={() => {
              gaClick('Side bar Contact');
            }}>
            <FontAwesomeIcon icon={faEnvelope} className={gStyles.both_icon} />
          </Link>
        </Rotate>
      </div>
    </>
  );
}
