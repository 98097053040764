import React, { useRef } from 'react';
import 'react-image-lightbox/style.css';
import styles from './about.module.css';
import Resume from './resumeContent';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactGA from 'react-ga4';

export default function CRI() {
  const componentRef = useRef();

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      print at 1008px by 1008px for best graph results
    </Tooltip>
  );
  const gaClick = (button) => {
    ReactGA.event({
      category: 'About Page',
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const print = () => {
    gaClick('Print resume');
    handlePrint();
  };
  const { printIconContainer, printIcon } = styles;
  return (
    <>
      <Resume ref={componentRef} />
      <div className={printIconContainer}>
        <OverlayTrigger
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}>
          <FontAwesomeIcon
            icon={faPrint}
            className={printIcon}
            onClick={print}
          />
        </OverlayTrigger>
      </div>
      <br />
      <br />
    </>
  );
}
