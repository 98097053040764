import React from 'react';
import Bounce from 'react-reveal/Bounce';
import styles from './resume.module.css';

const ResumeItems = (props) => {
  const {
    noPrint,
    wrp,
    titleSize,
    subtitleSize,
    detailItems,
    maskBackground,
    fitSize,
  } = styles;
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let dateString = '';

  const getDateString = (date) => {
    if (typeof date === 'string') date = new Date(date);
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`; // ex: July 2020
  };

  if (props?.t?.dateStart)
    dateString += `${getDateString(props.t.dateStart)} – `;

  if (props.t.dateEnd) {
    dateString += getDateString(props.t.dateEnd);
  } else {
    dateString += 'Present';
  }

  return (
    <Bounce right cascade>
      <div className={props.t.noPrint ? noPrint : ''}>
        <table className={wrp}>
          <tbody>
            <tr className={titleSize}>
              <td>{props.t.title}</td>
              <td>{dateString}</td>
            </tr>
            <tr className={subtitleSize}>
              <td>{props.t.company}</td>
              <td>{props.t.location}</td>
            </tr>
          </tbody>
        </table>
        <ul className={`${detailItems} ${props.ed ? noPrint : ''}`}>
          {props.t.details.map((d) => {
            let hide = false;
            if (d.substring(0, 2) === '^^') {
              hide = true;
              d = d.slice(2);
            }
            return (
              <li key={d} className={`${hide ? noPrint : ''}`}>
                <span className={`${maskBackground} ${fitSize} `}>{d}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </Bounce>
  );
};

export default ResumeItems;
