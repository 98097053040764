// number of instructors mentored
// let currentDate = new Date();
let startDate = new Date(2018, 9, 1);
let TGL_end = new Date(2022, 9, 1);
let numMonths =
  TGL_end.getMonth() -
  startDate.getMonth() +
  12 * (TGL_end.getFullYear() - startDate.getFullYear());
let numInstructorsRoundedDown = Math.round((numMonths * 1.6) / 10) * 10;

// ^^ - noprint

const status = {
  lookingForJob: true,
};

const ResumeData = {
  experience: [
    {
      title: 'Computer Science Professor',
      dateStart: new Date(2019, 7, 1),
      dateEnd: null,
      company: 'BYU-Idaho',
      location: 'Rexburg, ID',
      noPrint: false,
      edBool: true,
      devBool: false,
      details: [
        'Created courses focused on the MERN stack (MongoDB, Express.js, React, Node.js)',
        'Taught programming classes in Python, Java, C++, native Android development, and  web engineering classes utilizing HTML, CSS, JavaScript, PHP, Node.js, Postgres, and MongoDB',
        'Mentored thousands of students in professional development and career research',
      ],
    },
    {
      title: 'Staff Software Engineer',
      dateStart: new Date(2022, 8, 6),
      dateEnd: null,
      company: 'Vivacity Tech',
      location: 'Remote',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Spearheaded the creation and leadership of a dynamic team of 20 software professionals, fostering a collaborative environment conducive to innovation and productivity.',
        'Oversaw multiple software development teams, orchestrated the end-to-end delivery of cutting-edge Node.js and React projects in TypeScript, demonstrating expertise in architecting scalable solutions from inception to deployment.',
        'Elevated efficiency and reliability by serving as the Database Administrator (DBA) for a various databases, ensuring optimal performance and integrity across various environments.',
        'Pioneered advanced automation scripting initiatives for Jira, GitHub, database management, scrum workflows and access control, streamlining workflows and enhancing overall productivity through innovative solutions.',
        'Leveraged industry-leading tools such as Launch Darkly and Datadog to drive continuous improvement and optimization efforts, enabling real-time monitoring and actionable insights for enhanced decision-making.',
        'Held the pivotal role of SCRUM Master, orchestrating meticulous project planning, sprint management, and task allocation, resulting in the seamless execution of project timelines and the successful delivery of high-impact solutions on a global scale.',
        'Managed the planning and communication of OKRs and KPIs for all software initiatives within the company',
        'Continuously optimized development processes by implementing CI/CD pipelines in GitHub, ensuring rapid and reliable deployment of code while maintaining rigorous quality standards.',
      ],
    },
    {
      title: 'Application Developer',
      dateStart: new Date(2022, 3, 25),
      dateEnd: new Date(2022, 8, 6),
      company: '1-800 Flowers',
      location: 'Remote',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Positioned as development lead, managed repositories, and performed roughly 10 code reviews per week',
        'Developed and supported web applications built with React, Redux, TypeScript, and GraphQL',
        'Created full-stack applications with REST APIs in Node.js/TypeScript on the backend and React on the frontend',
        '^^Utilized MongoDB and Auth0 to manage over 10,000,000 users',
        '^^Monitored production environment health status using Datadog',
      ],
    },
    {
      title: 'Instructional Specialist',
      dateStart: new Date(2021, 8, 1),
      dateEnd: new Date(2022, 3, 1),
      company: 'Trilogy Education',
      location: 'Remote',
      noPrint: true,
      edBool: true,
      devBool: false,
      details: [
        'Specialized in the instruction of full-stack web development courses',
        'Assisted students in learning the MERN stack (MongoDB, Express.js, React.js, & Node.js)',
      ],
    },
    {
      title: 'Web Development Expert',
      dateStart: new Date(2021, 3, 1),
      dateEnd: new Date(2022, 3, 1),
      company: 'Digital Workshop Center',
      location: 'Remote',
      noPrint: false,
      edBool: true,
      devBool: false,
      details: [
        'Designed curriculum, lessons, and assignments for courses that ended up being used by the entire company',
        'Instructed classes in web design and development, web tools, HTML and CSS, entry-level JavaScript, advanced JavaScript, and React',
        'Mentored students and assisted them in professional development pursuits',
      ],
    },
    {
      title: 'Online Instructor',
      dateStart: new Date(2018, 8, 1),
      dateEnd: new Date(2022, 3, 1),
      company: 'BYU-Idaho',
      location: 'Remote',
      noPrint: false,
      edBool: true,
      devBool: false,
      details: [
        'Taught programming classes in HTML, CSS, JavaScript, Java, and Android Development',
        `Mentored over ${numInstructorsRoundedDown} online instructors and assisted with many classes`,
        'Assisted in the planning, designing, and maintenance of courses taken by software engineering students worldwide',
      ],
    },
    {
      title: 'Dev Lead and Internship Coordinator',
      dateStart: new Date(2020, 3, 1),
      dateEnd: new Date(2022, 3, 1),
      company: 'Allucent',
      location: 'Remote',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Launched a state-of-the-art SaaS product for the construction industry using VueJS and NoSQL database (Firebase)',
        'Employed Node.js to build scalable RESTful APIs',
        '^^Led the process of interviewing, hiring, training, and managing over 10 software engineering interns',
        '^^Developed advanced reporting systems, visual graphics, and graphical metrics all in JavaScript',
      ],
    },
    {
      title: 'Contract Full Stack Developer',
      dateStart: new Date(2020, 0, 1),
      dateEnd: new Date(2020, 6, 1),
      company: 'Feichtner Data Group',
      location: 'Remote',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Created Python script to access interstate cameras and sensors, then save data to MongoDB',
        'Pioneered full-stack web application from whiteboard to production in Node.js, Express, and EJS that displayed real-time data from MongoDB to law enforcement officials',
        '^^Used Node.js to connect to and communicate with a PostgreSQL database',
      ],
    },
    {
      title: 'Software Developer',
      dateStart: new Date(2016, 3, 1),
      dateEnd: new Date(2019, 4, 1),
      company: 'General Motors',
      location: 'Austin, TX',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Positioned as Dev Lead, managing several projects over an international team of 10+ engineers',
        'Published multiple complex web applications utilizing Angular, AngularJS, HTML5, CSS3, REST, and Java Spring Boot',
        '^^Wrote RESTful APIs with Java Spring Boot to communicate with Oracle SQL Database',
        '^^Constructed innovative mobile applications for both Android and iOS in modern technologies such as Angular, Cordova, PhoneGap, and Ionic',
        '^^Wrote software documentation that increased productivity for various teams around the company',
      ],
    },
    {
      title: 'Online English Teacher',
      dateStart: new Date(2016, 11, 1),
      dateEnd: new Date(2018, 7, 1),
      company: 'VIP Kid',
      location: 'Remote',
      noPrint: true,
      edBool: true,
      devBool: false,
      details: ['Taught English as a second language to children in China'],
    },
    {
      title: 'Software Development Engineer',
      dateStart: new Date(2015, 3, 1),
      dateEnd: new Date(2016, 3, 1),
      company: 'The Church of Jesus Christ of Latter-day Saints',
      location: 'Riverton, UT',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Produced advanced client/server web applications using ASP.NET, AngularJS, C#, & Oracle SQL Database',
        'Implemented advanced CSS and JavaScript techniques to provide beautifully responsive and performant applications',
        '^^Collaborated and worked with small teams on complex, interactive projects implementing agile methodologies of project development and management',
      ],
    },
    {
      title: 'Web Developer and Strategist',
      dateStart: new Date(2014, 8, 1),
      dateEnd: new Date(2015, 3, 1),
      company: 'BYU-Idaho',
      location: 'Rexburg, ID',
      noPrint: false,
      edBool: false,
      devBool: true,
      details: [
        'Planned and implemented various university websites using HTML5, CSS3, and JavaScript that helped educate students',
        '^^Worked with CSS and JavaScript libraries such as Bootstrap',
      ],
    },
  ],
  education: [
    {
      title: "Master's in Computer Information Systems, Security",
      dateStart: null,
      dateEnd: new Date(2019, 3, 1),
      company: 'Boston University',
      location: 'Boston, MA',
      noPrint: false,
      edBool: false,
      devBool: false,
      details: [
        'Business Data Communication and Networks',
        'Database Design and Implementation for Business (SQL and NoSQL)',
        'Information Systems Analysis and Design',
        'IT Strategy and Management',
        'Information Structures with Python',
        'Introduction to Probability and Statistics',
        'Enterprise Cybersecurity Management',
        'Enterprise Cyber Security',
        'Database Security',
        'Network Security',
      ],
    },
    {
      title: 'Bachelor of Science in Information Technology',
      dateStart: null,
      dateEnd: new Date(2016, 3, 1),
      company: 'Brigham Young University - Idaho',
      location: 'Rexburg, ID',
      noPrint: false,
      edBool: false,
      devBool: false,
      details: [
        'Object Oriented Programming',
        'Database Design & Development',
        'System Analysis and Design',
        'Database Administration',
        'Systems Security',
        'Mobile Application Development',
        'Web Frontend Development',
        'Web Backend Development',
        'Networking and Network Design',
        'Operating Systems',
      ],
    },
    {
      title: "Associate's Degree",
      dateStart: null,
      dateEnd: new Date(2013, 11, 1),
      company: 'Spokane Community College',
      location: 'Spokane, WA',
      noPrint: false,
      edBool: false,
      devBool: false,
      details: [],
    },
  ],
  skills: [
    { title: 'Web Design', dateStart: new Date(2019, 3, 16), dateEnd: null },
    { title: 'HTML', dateStart: new Date(2014, 4, 16), dateEnd: null },
    { title: 'CSS', dateStart: new Date(2014, 4, 16), dateEnd: null },
    { title: 'JavaScript', dateStart: new Date(2014, 3, 1), dateEnd: null },
    { title: 'Bootstrap', dateStart: new Date(2015, 3, 16), dateEnd: null },
    { title: 'React', dateStart: new Date(2020, 5, 16), dateEnd: null },
    { title: 'Next.js', dateStart: new Date(2021, 3, 16), dateEnd: null },
    { title: 'Vue.js', dateStart: new Date(2020, 3, 16), dateEnd: null },
    {
      title: 'Angular',
      dateStart: new Date(2016, 6, 16),
      dateEnd: new Date(2019, 3, 16),
    },
    {
      title: 'Angular.js',
      dateStart: new Date(2015, 3, 16),
      dateEnd: new Date(2017, 1, 6),
    },
    { title: 'TypeScript', dateStart: new Date(2020, 5, 16), dateEnd: null },
    { title: 'Node.js', dateStart: new Date(2018, 7, 16), dateEnd: null },
    {
      title: 'PHP',
      dateStart: new Date(2014, 7, 16),
      dateEnd: new Date(2015, 11, 4),
    },
    {
      title: 'C#',
      dateStart: new Date(2014, 7, 16),
      dateEnd: new Date(2015, 7, 16),
    },
    {
      title: 'Java',
      dateStart: new Date(2020, 7, 16),
      dateEnd: null,
    },
    { title: 'Python', dateStart: new Date(2019, 5, 16), dateEnd: null },
    { title: 'MongoDB', dateStart: new Date(2019, 7, 16), dateEnd: null },
    {
      title: 'Firebase',
      dateStart: new Date(2016, 3, 16),
      dateEnd: new Date(2021, 7, 16),
    },
    {
      title: 'EasyBase',
      dateStart: new Date(2020, 7, 16),
      dateEnd: new Date(2021, 6, 16),
    },
    {
      title: 'PostgreSQL',
      dateStart: new Date(2019, 7, 16),
      dateEnd: new Date(2021, 7, 16),
    },
    {
      title: 'MySQL',
      dateStart: new Date(2014, 7, 16),
      dateEnd: new Date(2016, 7, 16),
    },
    {
      title: 'PL/SQL',
      dateStart: new Date(2015, 7, 16),
      dateEnd: new Date(2018, 7, 16),
    },
    {
      title: 'Android',
      dateStart: new Date(2019, 7, 16),
      dateEnd: new Date(2020, 7, 16),
    },
    {
      title: 'PWAs',
      dateStart: new Date(2019, 7, 16),
      dateEnd: null,
    },
  ],
  softSkills: [
    {
      title: 'Communication',
      description:
        'Communicates exceptionally well both in person and with communication tools such as Slack or Teams',
    },
    {
      title: 'Organization',
      description: 'Organizes project development and delegation effectively',
    },
    {
      title: 'Leadership',
      description:
        'Leads diverse teams to success through service, hard work, and friendship',
    },
    {
      title: 'Creativity',
      description:
        'Loves to create and pushes the limits of what technology can do',
    },
    {
      title: 'Diligence',
      description:
        'Diligently and tirelessly performs duties within a team and as an individual contributor',
    },
    {
      title: 'Time management',
      description:
        'Manages multiple projects simultaneously, prioritizing tasks based on the customer and the team',
    },
    {
      title: 'Confidence',
      description:
        'Tackles new challenges with confidence, seeking feedback from developers and others on the team regularly',
    },
    {
      title: 'Adaptability',
      description:
        'Receives new projects, teams, and tasks openly, able to adjust to any environment',
    },
    {
      title: 'Accountability',
      description:
        'Takes responsibility for his work on the good days and the rough days',
    },
    {
      title: 'Persistence',
      description:
        'Continues work on development tasks until a solution is found. ',
    },
    {
      title: 'Proactivity',
      description: 'Proactively completes tasks and moves on to the next ones',
    },
    {
      title: 'Problem Solving & Critical thinking',
      description:
        'Can work through considerable architectural hurdles as well as daily development nuances',
    },
    {
      title: 'Attention to detail',
      description: 'Picks up on small things that many developers do not',
    },
    {
      title: 'Management',
      description:
        'Manages teams and projects in a way that leads to team happiness and project success',
    },
    {
      title: 'Optimism',
      description: 'Maintains a positive attitude even when things go awry',
    },
    {
      title: 'Teamwork',
      description:
        'Loves working with others, whether as a leader or individual contributor',
    },
    {
      title: 'Patience',
      description:
        'Is super patient with others and pretty patient with himself ;)',
    },
  ],
};

export { ResumeData, status };
