import React from 'react';
import styles from './home.module.css';
import Birch from '../../assets/img/birch-bw.jpg';
import { Container, Row, Col, Image } from 'react-bootstrap';
import SocialIcons from '../../components/social_icons';

function Home_Right() {
  const { homeRight, homeRightMain, homeRightMainImg, homeRightFooter } =
    styles;
  return (
    <Container className={homeRight}>
      <Row className={homeRightMain}>
        <Col xl={12}>
          <br />
          <Image
            src={Birch}
            className={homeRightMainImg}
            alt='Image of Nathan Birch'
          />
        </Col>
        <Row className={homeRightFooter}>
          <SocialIcons />
        </Row>
      </Row>
    </Container>
  );
}

export default Home_Right;
