import React, { Component } from 'react';
import gStyles from '../../global.module.css';
import styles from './personal.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import Animate from 'react-smooth';
import { Helmet } from 'react-helmet';
import HamburgerMenu from '../../components/hamburger_menu';
import particles from '../../const/personal_particle.js';
import Particles from 'react-particles-js';
import LeftContent from './leftContent';
import RightContent from './rightContent';
import ReactGA from 'react-ga4';

class Personal extends Component {
  componentDidMount() {
    ReactGA.pageview('Personal');
  }
  render() {
    return (
      <Container className={gStyles.aboutHeader} fluid={true}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Personal | Nathan Birch</title>
          <link rel='canonical' href='https://nathanbirch.one/project' />
          <meta
            name='description'
            content='Nathan Birch | Project - Product Manager | Project Manager | Software Engineer
                Experienced Project & Product Manager with a demonstrated history of working in computer software industry. 
                Skilled in leading edge project management, web & software development tools & trends. 
                Strong computer engineering base with a BSc in Computer Science focused in Computer Software Engineering from BRAC University. 
                In addition to that minor on Basic Business studies to assist me through product & project management'
          />
        </Helmet>
        <Animate to='1' from='0' attributeName='opacity'>
          <Particles params={particles} className={gStyles.particle} />
          <HamburgerMenu />
          <Row className={styles.noM}>
            <Col xl={4}>
              <LeftContent />
            </Col>
            <Col xl={8}>
              <RightContent />
            </Col>
          </Row>
          <br />
          <Row className={gStyles.aboutFooter}>
            <Footer />
          </Row>
        </Animate>
      </Container>
    );
  }
}

export default Personal;
