import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './social.module.css';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ReactGA from 'react-ga4';

function SocialIcons() {
  const locationString = useLocation().pathname.substring(1);
  const gaClick = (button) => {
    ReactGA.event({
      category: `Social Icons - ${locationString}`,
      action: `${button} Button`,
      label: 'Button clicked!',
    });
  };
  const { socialIcons, git, linkedin } = styles;
  return (
    <Col xl={12} className={socialIcons}>
      &nbsp; &nbsp;
      <a
        href='https://github.com/nathanbirch'
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => {
          gaClick('GitHub');
        }}>
        <FontAwesomeIcon className={git} icon={faGithub} />
      </a>
      <a
        href='https://www.linkedin.com/in/nathantbirch/'
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => {
          gaClick('LinkedIn');
        }}>
        <FontAwesomeIcon className={linkedin} icon={faLinkedin} />
      </a>
    </Col>
  );
}

export default SocialIcons;
