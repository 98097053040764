import React from 'react';
import { Link } from 'react-router-dom';
import MyButton from './button';
import styles from './b.module.css';

function LinkButton(props) {
  const { linkButton } = styles;
  return (
    <Link
      to={props.path}
      style={{ textDecoration: 'none', color: 'white' }}
      className={linkButton}>
      <MyButton {...props} />
    </Link>
  );
}

export default LinkButton;
